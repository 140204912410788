import React, { FC } from "react";
import { useSelector } from "react-redux";
import { DefaultDisplayPicture } from "utils/config";
import { getDisplayPicture, getUserDataUpdateTime } from "store/User/helpers";
import { GoogleStorageAsset } from "component/GoogleStorageAsset";

export const Avatar: FC<{ className?: string }> = ({ className }) => {
  const url = useSelector(getDisplayPicture);
  const ts = useSelector(getUserDataUpdateTime);

  return (
    <GoogleStorageAsset key={ts} src={url} fallback={DefaultDisplayPicture}>
      {({ url }) => (
        <div
          style={{ backgroundImage: `url("${url}")` }}
          className={`avatar ${className}`}
        >
          &nbsp;
        </div>
      )}
    </GoogleStorageAsset>
  );
};

export default Avatar;
