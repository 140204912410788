import React, { ReactNode, Suspense } from "react";
import { render } from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { HeadProvider } from "react-head";
import { Provider, useSelector } from "react-redux";
import classNames from "classnames";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Route as Routes, LoggedOutRoutes, LoggedInRoutes } from "utils/config";
import store from "store";
import {
  isAllowed as userIsAllowed,
  isLoggedIn as userIsLoggedIn,
  isLoggingIn as userIsLoggingIn,
} from "store/User/helpers";
import Title from "component/Title";
import InitUser from "component/InitUser";
import SideMenu from "component/SideMenu";
import NotificationsSubscription from "component/NotificationsSubscription";
import Navbar from "component/Navbar";
import DialogContainer from "component/DialogContainer";
import Footer from "component/Footer";
import "./app.css";
import ScrollableContext from "component/ScrollableContext";

Sentry.init({
  dsn: "https://8a24d9052e464f19bc2848cc60ae99c0@o1119680.ingest.sentry.io/6154337",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const AppContainer = ({
  children,
}: {
  children: (
    isLoggingIn: boolean,
    isLoggedIn: boolean,
    isAllowed: boolean
  ) => ReactNode;
}) => {
  const isLoggedIn = useSelector(userIsLoggedIn);
  const isLoggingIn = useSelector(userIsLoggingIn);
  const isAllowed = useSelector(userIsAllowed);

  return (
    <div
      className={classNames([
        "flex min-h-screen flex-col md:grid",
        {
          "md:grid-cols-1": !isLoggedIn,
          "md:grid-cols-root": isLoggedIn,
        },
      ])}
    >
      {children(isLoggingIn, isLoggedIn, isAllowed)}
    </div>
  );
};

export const App = () => (
  <BrowserRouter>
    <Provider store={store}>
      <ScrollableContext.Provider
        value={{
          enableScroll: () => {
            document.querySelector("html").classList.remove("overflow-hidden");
          },
          disableScroll: () => {
            document.querySelector("html").classList.add("overflow-hidden");
          },
          isScrollable: () => {
            return document
              .querySelector("html")
              .classList.contains("overflow-hidden");
          },
        }}
      >
        <HeadProvider>
          <Title>UserID.GG</Title>
          <AppContainer>
            {(isLoggingIn, isLoggedIn, isAllowed) => (
              <>
                <InitUser />
                {!isLoggingIn || isAllowed === null ? (
                  <>
                    <SideMenu />
                    <div
                      className={`flex flex-1 flex-col justify-center pt-10 ${
                        !isLoggedIn ? "md:pt-[60px]" : ""
                      }`}
                    >
                      {isLoggedIn ? (
                        <>
                          {isAllowed === false ? (
                            <Route
                              key={0}
                              path={"*"}
                              render={() => {
                                const C = Routes.get("Blocked").Component;
                                return (
                                  <Suspense fallback={null}>
                                    <C />
                                  </Suspense>
                                );
                              }}
                            />
                          ) : (
                            <Switch>
                              <Redirect
                                from="/"
                                to={Routes.get("Dashboard").Path}
                                exact
                              />
                              {LoggedInRoutes.map(route => (
                                <Route
                                  key={route.Path}
                                  exact={route.Exact}
                                  path={route.Path}
                                  render={() => {
                                    const C = route.Component;
                                    return (
                                      <Suspense fallback={null}>
                                        <C />
                                      </Suspense>
                                    );
                                  }}
                                />
                              ))}
                            </Switch>
                          )}
                        </>
                      ) : (
                        <Switch>
                          {LoggedOutRoutes.map(route => (
                            <Route
                              key={route.Path}
                              exact={route.Exact}
                              path={route.Path}
                              render={() => {
                                const C = route.Component;
                                return (
                                  <Suspense fallback={null}>
                                    <C />
                                  </Suspense>
                                );
                              }}
                            />
                          ))}
                        </Switch>
                      )}
                      <Footer />
                    </div>
                    <NotificationsSubscription />
                    <DialogContainer />
                    <Navbar />
                  </>
                ) : null}
              </>
            )}
          </AppContainer>
        </HeadProvider>
      </ScrollableContext.Provider>
    </Provider>
  </BrowserRouter>
);

render(<App />, document.querySelector("#root"));
