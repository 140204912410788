import {
  getFirestore,
  setDoc,
  doc,
  getDocs,
  query,
  where,
  collection,
} from "firebase/firestore";
import { TUserNotification } from "types";
import { db } from "utils/firebase";

export const getUserNotifications = async (uid: string) => {
  try {
    if (!uid) return;
    const q = query(collection(db, "notifications"), where("uid", "==", uid));
    const notifDocs = await getDocs<TUserNotification>(q as any);
    const output: (TUserNotification & { id: string })[] = [];
    notifDocs.forEach(notif => output.push({ id: notif.id, ...notif.data() }));
    return output;
  } catch (e) {
    console.error("getUserNotifications", e);
    throw e;
  }
};

export const markAsRead = async (id: string, uid: string) => {
  try {
    if (!uid) return;
    setDoc(
      doc(db, "notifications", id),
      {
        "uid#read": `${uid}#true`,
        read: true,
      },
      { merge: true }
    );
  } catch (e) {
    console.error(e);
  }
};
