import { ReactNode, useEffect, useState } from "react";
import { TState } from "types";

export function useButtonProgress({
  idle,
  working,
  success,
}: Record<TState, ReactNode>): [ReactNode, (arg: TState) => void, TState];
export function useButtonProgress(
  { idle, working, success }: Record<TState, ReactNode>,
  promise?: Promise<any>
): ReactNode;
export function useButtonProgress(
  { idle, working, success }: Record<TState, ReactNode>,
  promise?: Promise<any>
): ReactNode | [ReactNode, (arg: TState) => void, TState] {
  const [state, setState] = useState<TState>("idle");

  useEffect(() => {
    if (promise) {
      (async () => {
        try {
          setState("working");
          await promise;
          setState("success");
          setTimeout(() => {
            setState("idle");
          }, 1_500);
        } catch (e) {
          return;
        } finally {
          setState("idle");
        }
      })();
    }
  }, [promise]);

  let output: ReactNode;
  switch (state) {
    case "working":
      output = working;
      break;
    case "success":
      output = success;
      break;
    case "idle":
    default:
      output = idle;
      break;
  }

  return !promise ? [output, setState, state] : output;
}

export default useButtonProgress;
