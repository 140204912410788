import { configureStore } from "@reduxjs/toolkit";
import userReducer from "store/User";
import serviceConnectionsReducer from "store/UserOauthConnections";
import sharedReducer from "store/Shared";
import notificationsReducer from "store/UserNotifications";
import translationsReducer from "store/Translations";

export const store = configureStore({
  reducer: {
    user: userReducer,
    serviceConnections: serviceConnectionsReducer,
    shared: sharedReducer,
    notifications: notificationsReducer,
    translations: translationsReducer,
  },
  devTools: true,
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
