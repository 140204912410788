import React, { lazy, Suspense, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDialog } from "store/Shared";
import { getActiveDialog } from "store/Shared/helpers";

const Login = lazy(() => import("component/LoginDialog"));
const Signup = lazy(() => import("component/SignupDialog"));
const ResetPassword = lazy(() => import("component/ResetPasswordDialog"));

export const DialogContainer = () => {
  const activeDialog = useSelector(getActiveDialog);
  const dispatch = useDispatch();

  return (
    <>
      {activeDialog === "login" && (
        <Suspense fallback={null}>
          <Login
            isOpen={activeDialog === "login"}
            onClose={() => {
              dispatch(setDialog(null));
            }}
          />
        </Suspense>
      )}
      {activeDialog === "signup" && (
        <Suspense fallback={null}>
          <Signup
            isOpen={activeDialog === "signup"}
            onClose={() => {
              dispatch(setDialog(null));
            }}
          />
        </Suspense>
      )}
      {activeDialog === "resetPassword" && (
        <Suspense fallback={null}>
          <ResetPassword
            isOpen={activeDialog === "resetPassword"}
            onClose={() => {
              dispatch(setDialog(null));
            }}
          />
        </Suspense>
      )}
    </>
  );
};

export default DialogContainer;
