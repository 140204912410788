import React, { forwardRef, ReactElement } from "react";
import classnames from "classnames";

export const Icon = forwardRef<
  any,
  {
    className?: string;
    type: string;
    as?: ReactElement | JSX.IntrinsicElements;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
  }
>(({ className = "", type, as = "span", ...props }, ref) => {
  const As = as;
  return (
    <As
      className={classnames(["material-icons-round", "icon", className])}
      ref={ref}
      {...props}
    >
      {type}
    </As>
  );
});

export default Icon;
