import React, { Suspense, lazy, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { signOut } from "firebase/auth";
import classNames from "classnames";
import { auth } from "utils/firebase";
import useOnClickOutside from "hooks/useClickOutside";
import useMediaQuery from "hooks/useMediaQuery";
import { RootState } from "store";
import { clearData as userClearData } from "store/User";
import { getUserId as userGetUserId } from "store/User/helpers";
import {
  getSideMenu as sharedGetSideMenu,
  getSideMenuMain as sharedGetSideMenuMain,
  isSideMenuVisible,
} from "store/Shared/helpers";
import {
  setSideMenu as sharedSetSideMenu,
  setSideMenuMain as sharedSetSideMenuMain,
  setSideMenuVisibility,
} from "store/Shared";
import { hasUnreadNotifications as notificationsHasUnreadNotifications } from "store/UserNotifications/helpers";
import { getUserNotifications } from "store/UserNotifications";
import Icon from "component/Icon";
import WithTooltip from "component/WithTooltip";

const Notifications = lazy(() => import("component/Notifications"));
const FriendsList = lazy(() => import("component/FriendsList"));
const SidePanelIntegrations = lazy(
  () => import("component/SidePanelIntegrations")
);
const SideMenuIntegrations = lazy(
  () => import("component/SideMenuIntegrations")
);
const ProfilePanel = lazy(() => import("component/ProfilePanel"));
const ProfileAccount = lazy(() => import("component/ProfileAccount"));
const ProfilePersonalisation = lazy(
  () => import("component/ProfilePersonalisation")
);
const ProfilePrivacy = lazy(() => import("component/ProfilePrivacy"));

const MenuIconClasses = `relative inline-flex flex-col rounded row-span-1 text-white no-underline cursor-pointer z-10 center before:transition-height before:left-0 before:rounded before:absolute`;
const ActiveClasses = (isTrue: boolean) => ({
  "before:border-r-4 before:border-r-white before:h-full": isTrue,
  "hover:before:border-r-4 hover:before:border-r-white hover:before:h-1/2":
    !isTrue,
});

export const SideMenu = () => {
  const uid = useSelector(userGetUserId);
  const isVisible = useSelector(isSideMenuVisible);
  const activePanel = useSelector(sharedGetSideMenu);
  const activeMain = useSelector(sharedGetSideMenuMain);
  const hasNotifications = useSelector(notificationsHasUnreadNotifications);
  const isActive = activePanel !== null;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const { isMobile } = useMediaQuery();

  useOnClickOutside(ref, () => {
    setActivePanel(null);
    if (isMobile) {
      dispatch(setSideMenuVisibility(false));
    }
  });

  const doLogout = async () => {
    try {
      await signOut(auth);
      dispatch(userClearData());
      history.push("/");
    } catch (e) {
      console.error(e);
    }
  };

  const setActivePanel = (panel: RootState["shared"]["sideMenu"]["panel"]) => {
    dispatch(sharedSetSideMenu(panel));
  };

  const toggleActivePanel = (
    panel: RootState["shared"]["sideMenu"]["panel"]
  ) => {
    setActiveMain(null);
    if (activePanel === panel) {
      setActivePanel(null);
    } else {
      setActivePanel(panel);
    }
  };

  const setActiveMain = (panel: RootState["shared"]["sideMenu"]["main"]) => {
    dispatch(sharedSetSideMenuMain(panel));
  };

  const toggleActiveMain = (main: RootState["shared"]["sideMenu"]["main"]) => {
    if (activeMain === main) {
      setActiveMain(null);
    } else {
      setActiveMain(main);
    }
  };

  const closeSideMenu = () => dispatch(setSideMenuVisibility(false));

  useEffect(() => {
    dispatch(getUserNotifications());
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash.includes("favourites")) {
      dispatch(setSideMenuVisibility(true));
      setActivePanel("favorite");
    }
  }, []);

  if (!uid) {
    return null;
  }

  return (
    <div
      className={classNames([
        "absolute top-0 isolate z-10 h-screen max-h-screen transition duration-200 md:sticky md:-translate-x-0",
        {
          "-translate-x-full": !isVisible,
        },
      ])}
      ref={ref}
    >
      {/* == LEFT SIDE MENU == */}
      <div className="relative z-20 grid h-full w-12 bg-brand-grey-1 md:z-30 md:w-auto">
        {isMobile && (
          <Icon
            className={classnames(["hover:bg-brand-grey-2", MenuIconClasses])}
            type="close"
            onClick={() => closeSideMenu()}
          />
        )}
        <WithTooltip
          tooltip={<span className="tooltip">Notifications</span>}
          className={classnames([
            MenuIconClasses,

            ActiveClasses(activePanel === "notifications"),
          ])}
          placement="right"
          isDisabled={isMobile || activePanel === "notifications"}
        >
          <Icon
            type={hasNotifications ? "notification_important" : "notifications"}
            className={MenuIconClasses}
            onClick={() => toggleActivePanel("notifications")}
          />
        </WithTooltip>
        <WithTooltip
          tooltip={<span className="tooltip">Friends</span>}
          className={classnames([
            MenuIconClasses,

            ActiveClasses(activePanel === "favorite"),
          ])}
          placement="right"
          isDisabled={isMobile || activePanel === "favorite"}
        >
          <Icon
            type="favorite"
            onClick={() => toggleActivePanel("favorite")}
            className={MenuIconClasses}
          />
        </WithTooltip>
        <WithTooltip
          className={classnames([
            MenuIconClasses,

            ActiveClasses(activePanel === "profile"),
          ])}
          tooltip={<span className="tooltip">Settings</span>}
          placement="right"
          isDisabled={isMobile || activePanel === "profile"}
        >
          <Icon
            className={MenuIconClasses}
            onClick={() => toggleActivePanel("profile")}
            type="settings"
          />
        </WithTooltip>
        <WithTooltip
          className={classnames([
            MenuIconClasses,

            ActiveClasses(activeMain === "integrations"),
          ])}
          tooltip={<span className="tooltip">Integrations</span>}
          placement="right"
          isDisabled={isMobile || activeMain === "integrations"}
        >
          <Icon
            className={MenuIconClasses}
            onClick={() => {
              toggleActivePanel("integrations");
              toggleActiveMain("integrations");
            }}
            type="add"
          />
        </WithTooltip>
        <WithTooltip
          className={classnames([
            MenuIconClasses,
            "row-start-20",

            ActiveClasses(false),
          ])}
          tooltip={<span className="tooltip">Log out</span>}
          placement="right"
        >
          <Icon className={MenuIconClasses} onClick={doLogout} type="logout" />
        </WithTooltip>
      </div>
      {/* == SLIDE OUT PANEL == */}
      <aside
        className={classNames([
          "absolute inset-0 z-0 w-screen bg-brand-grey-2 pl-[50px] transition duration-200 md:z-20 md:max-w-xs",
          { "-translate-x-full ": !isActive },
        ])}
      >
        <span
          className="absolute top-2 right-2 cursor-pointer text-brand-grey-4 hover:text-brand-grey-5"
          onClick={closeSideMenu}
        >
          <Icon type="close" />
        </span>
        <Suspense fallback={null}>
          {activePanel === "integrations" && <SidePanelIntegrations />}
          {activePanel === "notifications" && <Notifications />}
          {activePanel === "favorite" && <FriendsList />}
          {activePanel === "profile" && <ProfilePanel />}
        </Suspense>
      </aside>
      {/* == MAIN SLIDE OUT CONTENT == */}
      <main
        className={classNames([
          "max-w-screen absolute inset-0 z-10 w-screen overflow-y-auto bg-brand-grey-3 pl-[50px] transition duration-200 md:z-0 md:pl-[320px]",
          { "-translate-x-full": activeMain === null },
        ])}
      >
        <span
          className="absolute top-2 right-2 cursor-pointer text-brand-grey-5 hover:text-brand-grey-6"
          onClick={() => {
            setActiveMain(null);
            setActivePanel(null);
          }}
        >
          <Icon type="close" />
        </span>
        <Suspense fallback={null}>
          {activeMain === "integrations" && <SideMenuIntegrations />}
          {activeMain === "profile.account" && <ProfileAccount />}
          {activeMain === "profile.personalisation" && (
            <ProfilePersonalisation />
          )}
          {activeMain === "profile.privacy" && <ProfilePrivacy />}
          {activeMain === "profile.verification" && null}
        </Suspense>
      </main>
    </div>
  );
};

export default SideMenu;
