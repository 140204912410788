import React from "react";
import { ENotificationTypes } from "types";

export const NotificationFromType = ({ type, meta }) => {
  switch (type) {
    case ENotificationTypes.FRIEND_REQUESTED:
      return <>You have a new friend request</>;
    case ENotificationTypes.FRIEND_CONFIRMED:
      return <>Your friend request was accepted!</>;
    case ENotificationTypes.CRM_SUBSCRIBED:
      return <>You have subscribed to the newsletter</>;
    default:
      return null;
  }
};

export default NotificationFromType;
