import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { fromUnixTime, formatRelative } from "date-fns";
import { onSnapshot, where, query, collection } from "firebase/firestore";
import { TUserNotification } from "types";
import { db } from "utils/firebase";
import { getUserId as userGetUserId } from "store/User/helpers";
import { markAsRead } from "store/UserNotifications/api";
import NotificationFromType from "component/NotificationFromType";
import Icon from "component/Icon";

export const NotificationsSubscription = () => {
  const uid = useSelector(userGetUserId);
  const [notifications, setNotifications] = useState<
    (TUserNotification & { id: string })[]
  >([]);

  const updateNotification = useCallback(
    (id, data) => {
      setNotifications(notifs => [
        ...notifs.map(notif => (notif.id === id ? data : notif)),
      ]);
    },
    [setNotifications]
  );

  const removeNotification = useCallback(
    id => setNotifications(notifs => [...notifs.filter(n => n.id !== id)]),
    [setNotifications]
  );

  useEffect(() => {
    if (!uid) return;
    const q = query(
      collection(db, "notifications"),
      where("uid#read", "==", `${uid}#false`)
    );
    const unsubscribe = onSnapshot(q, doc => {
      doc.docChanges().forEach(change => {
        if (change.type === "added") {
          const d = change.doc;
          console.log("new notification", d.data());
          setNotifications(notifs => [
            ...notifs,
            { id: d.id, ...(d.data() as TUserNotification) },
          ]);
        }
        if (change.type === "modified") {
          updateNotification(change.doc.id, change.doc.data());
        }
        if (change.type === "removed") {
          removeNotification(change.doc.id);
        }
      });
    });
    console.log("notifications subscribed");
    return () => {
      console.log("notifications unsubscribed");
      unsubscribe();
    };
  }, [uid, setNotifications, updateNotification]);

  return (
    <div className="fixed bottom-2 right-2 flex flex-col-reverse">
      {notifications.map(notification => (
        <div
          key={notification.id}
          className="relative mt-1 flex min-h-[80px] w-[250px] flex-col rounded border-2 border-brand-grey-2 bg-brand-grey-1 py-3 px-3 pr-6 shadow-sm"
        >
          <button
            className="absolute top-1 right-1 text-sm text-brand-grey-5 hover:cursor-pointer hover:text-brand-grey-6"
            onClick={() => markAsRead(notification.id, uid)}
          >
            <Icon type="close" />
          </button>
          <p className="flex-1 leading-5 text-brand-grey-9">
            <NotificationFromType
              type={notification.type}
              meta={notification.meta}
            />
          </p>
          <p className="mt-2 h-3 text-xs text-brand-grey-4">
            {formatRelative(
              fromUnixTime(notification.createdAt.seconds),
              new Date()
            )}
          </p>
        </div>
      ))}
    </div>
  );
};

export default NotificationsSubscription;
