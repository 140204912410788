import { createSelector } from "reselect";
import { RootState } from "store";

export const getNotifications = (state: RootState) => state.notifications.data;

export const getUnreadNotifications = createSelector(
  [(state: RootState) => state.notifications.data],
  notifs => notifs.filter(n => n.read === false)
);

export const hasNotifications = (state: RootState) =>
  getNotifications(state).length > 0;

export const hasUnreadNotifications = (state: RootState) =>
  getUnreadNotifications(state).length > 0;
