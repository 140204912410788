import { createSelector } from "reselect";
import { RootState } from "store";

export const getSideMenuVisibility = (state: RootState) =>
  state.shared.sideMenu.mobileVisible;

export const isSideMenuVisible = (state: RootState) =>
  getSideMenuVisibility(state) === true;

export const getSideMenu = (state: RootState) => state.shared.sideMenu.panel;

export const isSideMenuActive = (state: RootState) =>
  getSideMenu(state) !== null;

export const getSideMenuMain = (state: RootState) => state.shared.sideMenu.main;

export const isSideMenuMainActive = (state: RootState) =>
  getSideMenuMain(state) !== null;

export const getActiveDialog = (state: RootState) => state.shared.dialog;
