import React, { FC, Fragment, ReactElement, ReactText } from "react";
import { useSelector } from "react-redux";
import { getTranslation } from "store/Translations/helpers";

export const Translate: FC<{
  as?: ReactElement | JSX.IntrinsicElements;
  vars?: {
    [x: string]: string | number;
  };
  use: string;
}> = ({ use, as = Fragment, vars = {} }) => {
  let value = useSelector(getTranslation(use));
  const hasHTML = value ? Array.isArray(value.match(/<.+>/g)) : "";
  const variables = Object.keys(vars);
  if (value && variables.length > 0) {
    variables.forEach(vari => {
      const val = vars[vari];
      const re = new RegExp(`\\\[${vari}\\\]`, "g");
      value = value.replace(re, String(val));
    });
  }
  const Ele = as === Fragment && hasHTML ? "span" : as;
  if (!value) return null;
  return Ele === Fragment ? (
    <Ele>{value}</Ele>
  ) : (
    <Ele dangerouslySetInnerHTML={{ __html: value }} />
  );
};

export default Translate;
