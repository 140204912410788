import React, { FC } from "react";
import { Title as TitleComponent } from "react-head";

/**
 * TODO
 * Separate component to allow translations
 * in the future, and to consolidate any logic
 */

export const Title: FC<{}> = ({ children }) => {
  return <TitleComponent>{children}</TitleComponent>;
};

export default Title;
