import React, { FC, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { noop } from "lodash";
import { setDialog } from "store/Shared";
import SubmitButton, { Themes } from "component/SubmitButton";

export const LoginButton: FC<{
  className?: string;
  theme?: keyof typeof Themes;
  onClick?: () => void;
}> = ({ className, theme = "white", onClick = noop }) => {
  const dispatch = useDispatch();
  const showLogin = useCallback(() => {
    dispatch(setDialog("login"));
    onClick();
  }, [onClick]);
  return (
    <SubmitButton
      onClick={showLogin}
      theme={theme}
      className={`${className} h-auto! py-2`}
    >
      Log In
    </SubmitButton>
  );
};

export default LoginButton;
