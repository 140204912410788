import React, { FC, Fragment } from "react";
import { Build } from "utils/config";

export const Logo: FC<{
  type?: "long" | "short";
  className?: string;
  isLinked?: boolean;
}> = ({ type = "short", className, isLinked = true }) => {
  const LinkEle = isLinked ? "a" : Fragment;
  return (
    <LinkEle {...(isLinked ? { href: "/" } : {})}>
      <img
        src={`${Build.CDN}/useridlogo${type === "long" ? "_long" : ""}.svg`}
        className={`logo ${className}`}
      />
    </LinkEle>
  );
};

export default Logo;
