import React, { useState, useRef, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { NavLink as NavLinkComponent } from "react-router-dom";
import { Build, Route } from "utils/config";
import useMediaQuery from "hooks/useMediaQuery";
import useOnClickOutside from "hooks/useClickOutside";
import { populatePathRegexp } from "utils/browser";
import useButtonProgress from "hooks/useButtonProgress";
import { setSideMenuVisibility } from "store/Shared";
import { isSideMenuVisible } from "store/Shared/helpers";
import { isLoggedIn as userIsLoggedIn } from "store/User/helpers";
import { getUserHeaderInfo as userGetUserHeaderInfo } from "store/User/helpers";
import Icon from "component/Icon";
import SignupButton from "component/SignupButton";
import LoginButton from "component/LoginButton";
import Logo from "component/Logo";
import ScrollableContext from "component/ScrollableContext";
import Avatar from "component/Avatar";
import Translate from "component/Translate";

const NavLink = ({ className = "", ...props }) => (
  <NavLinkComponent
    className={`text-brand-cyan-1 no-underline transition visited:text-brand-cyan-1 hover:text-cyan-500 ${className}`}
    {...props}
  />
);

export const Navbar = () => {
  const { isMobile } = useMediaQuery();
  const isVisible = useSelector(isSideMenuVisible);
  const isLoggedIn = useSelector(userIsLoggedIn);
  const { enableScroll, disableScroll } = useContext(ScrollableContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const clickRef = useRef<HTMLDivElement>();
  const [isMobileVisible, setMobileVisibility] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const { displayName, slug } = useSelector(userGetUserHeaderInfo);

  useOnClickOutside(clickRef, () => {
    setMobileVisibility(false);
  });

  const toggleSideMenu = () => {
    if (isVisible) {
      dispatch(setSideMenuVisibility(false));
    } else {
      dispatch(setSideMenuVisibility(true));
    }
  };

  const toggleMobileNavbar = () => {
    setMobileVisibility(visible => !visible);
  };

  useEffect(() => {
    setMobileVisibility(false);
  }, [location]);

  useEffect(() => {
    if (isMobileVisible) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [isMobileVisible]);

  useEffect(() => {
    const scrollHandler = () => {
      setScrollTop(document.scrollingElement.scrollTop);
    };
    document.addEventListener("scroll", scrollHandler);
    return () => document.removeEventListener("scroll", scrollHandler);
  }, [setScrollTop]);

  const profileUrl = populatePathRegexp(Route.get("UserProfile").Path, {
    user: slug,
  });

  const [buttonText, setState] = useButtonProgress({
    idle: <Translate use="header_dropdown_copy_profile_link" />,
    working: <Translate use="header_dropdown_copy_profile_link" />,
    success: (
      <span className="center">
        <Icon type="check" className="text-sm" />
        &nbsp;
        <Translate use="header_dropdown_copied_profile_link" />
      </span>
    ),
  });

  const setCopy = () => {
    setState("success");
    setTimeout(() => {
      setState("idle");
    }, 1_000);
  };

  const copyToClipboard = (text: string) => () => {
    navigator.clipboard.writeText(text);
    setCopy();
  };

  return (
    <div
      className={`grid-start-1 col-span-2 flex w-screen items-center justify-start p-1 transition-colors md:px-10 ${
        !isMobile || (!isLoggedIn && scrollTop !== 0)
          ? "bg-brand-grey-0"
          : undefined
      }  fixed top-0 left-0 ${
        isLoggedIn ? "md:h-12 md:px-3 md:pl-[62px]" : "md:h-[60px] md:px-10"
      } ${isMobile ? "bg-brand-grey-1.5" : ""}`}
      ref={clickRef}
    >
      {/* Navbar */}
      {!isMobile && (
        <Logo type="long" className={isLoggedIn ? "h-8" : "h-16"} />
      )}
      {isMobile && (
        <>
          <div
            className={`inline-flex items-start ${
              isLoggedIn ? "w-8" : "w-[100px]"
            }`}
          >
            <button
              onClick={isLoggedIn ? toggleSideMenu : toggleMobileNavbar}
              className="h-8 w-8 rounded text-white hover:bg-brand-grey-3 active:bg-brand-grey-2"
            >
              <Icon type="menu" className="text-xl" />
            </button>
          </div>
          <div className="center flex-1">
            <Logo />
          </div>
          <div>
            {!isLoggedIn && <SignupButton theme="cyan" className="h-7 py-2" />}
          </div>
        </>
      )}
      {/* Slid out panel */}
      {!isLoggedIn ? (
        <div
          className={`fixed top-0 left-0 isolate flex h-screen w-screen flex-col bg-brand-grey-2
          transition md:relative md:ml-auto md:h-auto md:w-full md:flex-row md:items-center md:bg-transparent ${
            isMobileVisible
              ? "z-20 translate-x-0"
              : "-translate-x-full md:-translate-x-0"
          }`}
        >
          {isMobile && (
            <button
              onClick={isLoggedIn ? toggleSideMenu : toggleMobileNavbar}
              className="absolute top-2 left-2 inline-flex h-8 w-8 items-center justify-center rounded text-white hover:bg-brand-grey-3 active:bg-brand-grey-2"
            >
              <Icon type="close" className="text-xl" />
            </button>
          )}
          <div className="grid flex-1 grid-cols-1 grid-rows-navbar md:flex md:flex-row">
            {isMobile && <Logo className="mx-auto mt-2 mb-10" />}
            <div className="flex flex-col items-center justify-center md:ml-auto md:mt-0 md:flex-row">
              <LoginButton
                className="font-display text-2xl! font-normal md:ml-3 md:text-sm! md:font-bold"
                theme={isMobile ? "transparentLight" : "loginGrey"}
                onClick={() => setMobileVisibility(false)}
              />
              <SignupButton
                className="font-display text-2xl! font-normal md:ml-3 md:text-sm! md:font-bold"
                theme={isMobile ? "transparentLight" : "cyan"}
                onClick={() => setMobileVisibility(false)}
              />
            </div>
          </div>
          {isMobile && (
            <button className="center mt-auto pb-2">
              <a href="https://discord.gg/D29fxAxba6">
                <img src={`${Build.CDN}/join_on_discord.png`} />
              </a>
            </button>
          )}
        </div>
      ) : (
        <div className="center relative ml-auto h-full font-display text-lg font-bold text-brand-grey-11">
          <Menu>
            <Menu.Button className="center text-sm">
              {!isMobile && displayName}
              <Avatar className="ml-3 h-8 w-8 border-0" />
            </Menu.Button>
            <Menu.Items className="grid-gap-3 absolute top-12 right-1 grid w-36 grid-cols-1 grid-rows-3 rounded bg-brand-grey-11 p-2">
              {isMobile && (
                <Menu.Item
                  disabled
                  as="div"
                  className="text-sm font-normal text-brand-grey-9"
                >
                  {displayName}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <NavLink
                    className={`text-sm font-normal visited:text-brand-grey-4 ${
                      active ? "text-brand-grey-4" : "text-brand-grey-6"
                    }`}
                    to={Route.get("Dashboard").Path}
                  >
                    <Translate use="header_dropdown_home" />
                  </NavLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <NavLink
                    className={`text-sm font-normal visited:text-brand-grey-4 ${
                      active ? "text-brand-grey-4" : "text-brand-grey-6"
                    }`}
                    to={profileUrl}
                  >
                    <Translate use="header_dropdown_view_my_profile" />
                  </NavLink>
                )}
              </Menu.Item>
              <Menu.Item
                as="div"
                className="cursor-pointer text-sm font-normal text-brand-grey-4 hover:text-brand-cyan-1"
                onClick={copyToClipboard(
                  `${window.location.protocol}//${window.location.host}${profileUrl}`
                )}
              >
                {buttonText}
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default Navbar;
