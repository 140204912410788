import React, { useMemo } from "react";
import { format, fromUnixTime } from "date-fns";
import { Route, SocialMediaLinks } from "utils/config";
import Icon from "component/Icon";

const IconLink = ({ children, href }) => (
  <a
    href={href}
    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-brand-grey-2 text-brand-grey-6 hover:bg-brand-grey-10 hover:text-brand-grey-2"
  >
    {children}
  </a>
);

export const Footer = () => {
  const year = new Date().getFullYear();
  let buildTime = useMemo(() => {
    try {
      return format(fromUnixTime("BUILD_TIME" as any), "yyyy-MM-dd hh:mma");
    } catch (e) {
      return null;
    }
  }, []);

  return (
    <div className="relative grid h-[120px] w-full grid-cols-1 grid-rows-3 bg-brand-grey-0 py-4 font-display text-xs text-brand-grey-4 md:h-[80px] md:grid-cols-footer md:grid-rows-2 md:py-2">
      <div className="hidden md:col-start-1 md:row-span-2 md:row-start-1 md:block">
        &nbsp;
      </div>
      <div className="flex items-center justify-center">
        &copy; {year}, All Rights Reserved.
      </div>
      <div className="row-start-2 mt-2 flex items-center justify-center space-x-4 md:mt-0">
        <a href={Route.get("LegalTerms").Path}>Terms of Service</a>
        <a href={Route.get("LegalPrivacy").Path}>Privacy &amp; Cookie Policy</a>
      </div>
      <div className="row-start-1 flex items-center justify-center space-x-2 md:col-start-3 md:row-span-2">
        <IconLink href={Route.get("Contact").Path}>
          <Icon type="mail" className="text-base" />
        </IconLink>
        <IconLink href={SocialMediaLinks.Discord}>
          <span className="icon-discord text-base"></span>
        </IconLink>
        <IconLink href={SocialMediaLinks.Twitter}>
          <span className="icon-twitter text-base"></span>
        </IconLink>
        {buildTime && (
          <span className="absolute bottom-1 text-xxs text-brand-grey-2">
            &nbsp;Build time: {buildTime}
          </span>
        )}
      </div>
    </div>
  );
};

export default Footer;
