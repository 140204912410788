import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { TUserNotificationSerializable } from "types";
import { currentUser } from "utils/firebase";
import { getUserNotifications as getUserNotificationsApi } from "./api";

const initialState = {
  data: [] as TUserNotificationSerializable[],
  loading: false,
};

export const getUserNotifications = createAsyncThunk(
  "notifications/getUserNotifications",
  async () => {
    const { uid } = currentUser();
    let notifications = await getUserNotificationsApi(uid);
    const serializableNotifications: TUserNotificationSerializable[] = [];
    notifications.forEach(notif => {
      const newNotif = _.pickBy(notif, (v, k) => k !== "createdAt");
      newNotif.createdAtSerializable = notif.createdAt.seconds;
      serializableNotifications.push(newNotif);
    });
    return serializableNotifications;
  }
);

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<typeof initialState["data"]>) => {
      state.data = [...action.payload];
    },
    clearData: state => {
      state.data = initialState.data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUserNotifications.pending, state => {
        state.loading = true;
      })
      .addCase(getUserNotifications.fulfilled, (state, action) => {
        state.data = action.payload;
      });
  },
});

export const { setData, clearData } = notifications.actions;

export default notifications.reducer;
