import { createContext } from "react";

export default createContext({
  enableScroll: () => {
    document.querySelector("html").classList.remove("overflow-hidden");
  },
  disableScroll: () => {
    document.querySelector("html").classList.add("overflow-hidden");
  },
  isScrollable: () => {
    return document.querySelector("html").classList.contains("overflow-hidden");
  },
});
