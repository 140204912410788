import { createSlice } from "@reduxjs/toolkit";
import { TTranslations } from "types";
import { Route, SocialMediaLinks } from "utils/config";

const initialState = {
  data: {
    forgot_password_dialog_title: "Forgot your password?",
    forgot_password_dialog_description:
      "No problem. Enter the email you used when you joined userid.gg, and we’ll send you a link to reset your password, so you can come back to us!",
    forgot_password_success_description:
      "If we find an account associated with the email entered by you, we will send you a reset link. Please click that link for further instructions.",
    reset_password_success_description:
      "All done! Your new password has been set.",
    reset_password_error_1: "Please enter the same password twice",
    forgot_password_dialog_close_button: "Close",
    "404_title": "40-bloody-4!",
    "404_title_2": "Game error! Abort, abort!",
    "404_description": `It’s not you, it’s very probably us. You can check the URL to make sure you entered it correctly, or <a href="${
      Route.get("Contact").Path
    }">contact us</a> to let us know what happened and we’ll get right on it.`,
    contact_page_title: "Contact us",
    contact_page_description: `<p>We are humans who love to game, just like you. We want to hear from you on product improvements, feature requests or feedback, or if you just want to say hello. You can fill out the form below and one of us will get back to you. Alternatively, join us on <a href="${SocialMediaLinks.Discord}" target="_blank">Discord</a> or tweet at us as <a href="${SocialMediaLinks.Twitter}" target="_blank">@useridgg.</a>`,
    contact_page_success_title: "Thanks for your feedback!",
    contact_page_success_description: "",
    confirm_email_send_not_allowed:
      "Please wait [seconds] second(s) before trying again.",
    email_confirmed_badge: "Confirmed",
    user_overview_complete_your_profile: "Finish your profile",
    user_overview_view_my_profile: "View my profile",
    user_overview_share_profile_link: "Share profile link",
    user_overview_share_profile_link_copied: "Copied",
    user_overview_join_discord: "Join our Discord",
    user_overview_send_feedback: "Send feedback",
    user_overview_join_twitter: "Follow @useridgg",
    user_overview_welcome_description: `<p>Thanks for joining our community! Get started by setting up your profile and inviting your friends.</p><p class="mt-4"><3 UserID.GG team</p>`,
    user_profile_private_description: "This profile is private.",
    user_profile_add_friend_logged_in_description:
      "Add friend to request access.",
    user_profile_add_friend_logged_out_description:
      "Register and add friend to request access.",
    header_dropdown_home: "Home",
    header_dropdown_view_my_profile: "View my profile",
    header_dropdown_copy_profile_link: "Copy profile link",
    header_dropdown_copied_profile_link: "Copied",
    profile_privacy_change_password_description:
      "Changing your profile to private will only allow friends to see your information, alternatively changing individual links to private will make only those links friends only.",
    profile_personalisation_display_picture_description:
      "Upload your pubic display picture used on your profile. Maximum 2mb file size only png and jpeg allowed with recommended dimensions 250px × 250px.",
    profile_account_profile_url_description:
      "Choose your URL wisely, it will be used to make your unique UserID.GG link.",
    notifications_no_notifications_description: "Nothing to see here.",
    services_empty_title: "It's looking empty here",
    services_empty_title_description:
      "Sharing is caring! Head over to Integrations and add your first service.",
    integrations_panel_description:
      "Add your social profiles here. You can choose from the existing list or add your own.",
    landing_banner_second_line:
      "A user profile system for gamers and social influencers to consolidate their various profiles into a single, verified location.",
  } as TTranslations,
} as const;

const translations = createSlice({
  name: "translations",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export default translations.reducer;
