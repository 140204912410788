import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "utils/firebase";
import { clearData, getUserData, setLoggingIn, setData } from "store/User";

export const InitUser: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let unsub;
    dispatch(setLoggingIn(true));
    onAuthStateChanged(auth, user => {
      if (user) {
        dispatch(
          setData({
            uid: user.uid,
          })
        );
        onSnapshot(doc(db, "users", user.email), doc => {
          dispatch(setData(doc.data()));
        });
        dispatch(getUserData());
        dispatch(setLoggingIn(false));
      } else {
        dispatch(clearData());
        dispatch(setLoggingIn(false));
      }
    });
    return () => {
      if (unsub) unsub();
    };
  }, []);

  return <></>;
};

export default InitUser;
